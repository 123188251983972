<template>
  <v-navigation-drawer
    v-model="customizer.Sidebar_drawer"
    :permanent="$vuetify.display.mdAndUp"
    elevation="10"
    :class="customizer.SidebarColor == 'white' ? '' : 'text-white'"
    :color="customizer.darktheme ? '' : customizer.SidebarColor"
    rail-width="68"
    mobile-breakpoint="960"
    app
    :rail="customizer.miniSidebar"
    expand-on-hover
    :width="250"
  >
    <!-- ---------------------------------------------- -->
    <!---Navigation -->
    <!-- ---------------------------------------------- -->
    <div class="scrollnavbar">
      <div class="profile">
        <div class="profile-name">
          <h5>
            <span><v-avatar size="45">
              <img
                :src="auth.userinfo.picture"
                width="50"
                :alt="auth.userinfo.name"
              > </v-avatar></span>{{ auth.userinfo.family_name }} {{ auth.userinfo.given_name }} さん
          </h5>
        </div>
      </div>
      <v-list
        v-if="loaded"
        class="px-3 pt-0"
        color=""
      >
        <!-- ---------------------------------------------- -->
        <!---Menu Loop -->
        <!-- ---------------------------------------------- -->
        <template
          v-for="(item, i) in menu"
          :key="i"
        >
          <!-- ---------------------------------------------- -->
          <!---Item Sub Header -->
          <!-- ---------------------------------------------- -->
          <v-list-subheader
            v-if="item.header"
            class="ml-7n mt-5 font-weight-medium"
            color="black"
          >
            {{ item.header }}
          </v-list-subheader>

          <!-- ---------------------------------------------- -->
          <!---If Has Child -->
          <!-- ---------------------------------------------- -->
          <v-list-group
            v-else-if="item.children"
            class=""
          >
            <!-- ---------------------------------------------- -->
            <!---Dropdown  -->
            <!-- ---------------------------------------------- -->
            <template #activator="{ props }">
              <v-list-item
                v-bind="props"
                :value="item.title"
                rounded="lg"
                class="mb-1"
              >
                <!---Icon  -->
                <template #prepend>
                  <v-icon
                    :icon="item.icon"
                  />
                </template>
                <!---Title  -->
                <v-list-item-title class="mr-auto">
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <!-- ---------------------------------------------- -->
            <!---Sub Item-->
            <!-- ---------------------------------------------- -->
            <v-list-item
              v-for="(subitem, n) in item.children"
              :key="n"
              :value="subitem.to"
              :to="subitem.to"
              rounded="lg"
              class="first-level-item mb-1"
            >
              <template #prepend>
                <v-icon
                  :icon="subitem.icon"
                />
              </template>
              <v-list-item-title>{{ subitem.title }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
          <!-- ---------------------------------------------- -->
          <!---Single Item-->
          <!-- ---------------------------------------------- -->
          <v-list-item
            v-else
            :key="i"
            :to="item.to"
            rounded="lg"
            class="mb-1"
          >
            <template #prepend>
              <v-icon
                :icon="item.icon"
              />
            </template>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
          <!-- ---------------------------------------------- -->
          <!---End Single Item-->
          <!-- ---------------------------------------------- -->
        </template>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { SIDEBAR_ITEMS } from '@/configs/sidebar-item';
export default defineComponent({
  setup() {
    const { auth } = useAuth();
    const { customizer } = useCustomizerStore();
    const permissions = auth.value.userinfo.permissions;
    const loaded = ref(false);

    let menu: any = [];
    for (const key in SIDEBAR_ITEMS) {
      if (SIDEBAR_ITEMS[key].children) {
        let children: any = [];
        const items: any = SIDEBAR_ITEMS[key].children;
        items.forEach(function (child: any) {
          // 権限チェック
          if (permissions[child.name]) {
            children = children.concat(child);
          }
        });

        if (children.length === 0) {
          continue;
        }

        // １つ前のメニューがヘッダーの場合は、ヘッダーを追加
        if (SIDEBAR_ITEMS[Number(key) - 1].header) {
          menu = menu.concat(SIDEBAR_ITEMS[Number(key) - 1]);
        }
        const tmp: any = SIDEBAR_ITEMS[key];
        // 権限チェック後の子メニューで上書き
        tmp.children = children;
        menu = menu.concat(tmp);
      } else {
        if (SIDEBAR_ITEMS[key].to) {
          // 権限チェック
          if (permissions[SIDEBAR_ITEMS[key].name ?? '']) {
            // １つ前のメニューがヘッダーの場合は、ヘッダーを追加
            if (SIDEBAR_ITEMS[Number(key) - 1].header) {
              menu = menu.concat(SIDEBAR_ITEMS[Number(key) - 1]);
            }
            menu = menu.concat(SIDEBAR_ITEMS[key]);
          }
        }
      }
    }

    loaded.value = true;

    return {
      customizer,
      auth,
      loaded,
      menu
    };
  }
});
</script>

<style lang="scss">
#main-sidebar {
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  .v-navigation-drawer__border {
    display: none;
  }
  .v-list {
    padding: 8px 15px;
  }
  .v-list-item {
    &__icon--text,
    &__icon:first-child {
      justify-content: center;
      text-align: center;
      width: 20px;
    }
  }
  .profile-bg {
    background: url('/assets/images/user-info.jpg') no-repeat;
    .v-avatar {
      padding: 45px 0;
    }
  }
}
</style>
