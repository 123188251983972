<template>
  <v-app-bar
    :color="customizer.navbarColor"
    elevation="5"
    :priority="priority"
    :class="['v-topbar', 'text-white']"
  >
    <!-- ---------------------------------------------- -->
    <!---Logo part -->
    <!-- ---------------------------------------------- -->
    <div class="pa-4">
      <div class="logo">
        <NuxtLink
          to="/"
          class="d-flex"
        >
          <img
            src="/images/logo.png"
            class="pl-3"
            style="max-width: 150px"
          >
        </NuxtLink>
      </div>
    </div>
    <v-app-bar-nav-icon
      color="inherit"
      class="hidden-sm-and-down"
      @click.stop="setMiniSidbar(!customizer.miniSidebar)"
    />
    <v-app-bar-nav-icon
      color="inherit"
      class="hidden-md-and-up"
      @click.stop="SetSidebarIDrawer"
    />
    <v-spacer />
    <!-- ---------------------------------------------- -->
    <!---right part -->
    <!-- ---------------------------------------------- -->

    <!-- ---------------------------------------------- -->
    <!-- Notification -->
    <!-- ---------------------------------------------- -->
    <!-- <v-menu
      anchor="bottom end"
      origin="auto"
    >
      <template #activator="{ props }">
        <v-btn
          icon
          color="inherit"
          v-bind="props"
        >
          <v-badge
            color="error"
            dot
          >
            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list
        class="pa-6"
        elevation="10"
        rounded="lg"
        max-width="300"
      >
        <h4 class="d-flex">
          Notifications
          <v-chip
            class="ml-auto"
            label
            small
            color="error"
          >
            5 new
          </v-chip>
        </h4>
        <v-list-item
          v-for="(item, i) in notifications"
          :key="i"
          class="pa-3 mt-2"
          :value="item"
          rounded="lg"
          :title="item.title"
          :subtitle="item.desc"
        >
          <template #prepend>
            <v-avatar :color="item.color">
              <v-icon :icon="item.icon" />
            </v-avatar>
          </template>
        </v-list-item>
        <v-btn
          block
          variant="flat"
          color="secondary"
          class="mt-4 py-4"
        >
          See all Notifications
        </v-btn>
      </v-list>
    </v-menu> -->

    <!-- ---------------------------------------------- -->
    <!-- User Profile -->
    <!-- ---------------------------------------------- -->
    <v-menu
      anchor="bottom end"
      origin="auto"
      min-width="300"
    >
      <template #activator="{ props }">
        <v-btn
          v-bind="props"
          class="pa-0 px-1"
          elevation="0"
          color="transparent"
          plain
          :ripple="false"
        >
          <v-avatar size="35">
            <img
              :src="auth.userinfo.picture"
              width="40"
              :alt="auth.userinfo.name"
            >
          </v-avatar>
        </v-btn>
      </template>

      <v-list
        class="pa-6"
        elevation="10"
        rounded="lg"
      >
        <div class="d-flex align-center my-4">
          <img
            :src="auth.userinfo.picture"
            :alt="auth.userinfo.name"
            class="rounded-circle"
            width="90"
          >
          <div class="ml-4">
            <h4 class="font-weight-medium sfs-18">
              {{ auth.userinfo.family_name }} {{ auth.userinfo.given_name }} さん
            </h4>
            <div class="d-flex align-center">
              <v-icon icon="mdi-email" />
              <p class="subtitle-2 font-weight-light ma-1">
                {{ auth.userinfo.email }}
              </p>
            </div>
          </div>
        </div>

        <v-btn
          block
          color="secondary"
          to="/logout"
          variant="flat"
          class="mt-4 py-4"
        >
          Logout
        </v-btn>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script lang="ts">
export default defineComponent({
  setup() {
    const { auth } = useAuth();
    const { customizer, setMiniSidbar, SetSidebarIDrawer } = useCustomizerStore();

    // const notification = [
    //   {
    //     color: 'error',
    //     icon: 'mdi-home',
    //     title: 'Luanch Admin',
    //     desc: 'Just see the my new admin!'
    //   },
    //   {
    //     color: 'primary',
    //     icon: 'mdi-account-multiple',
    //     title: 'Event today',
    //     desc: 'Just a reminder that you have event'
    //   },
    //   {
    //     color: 'warning',
    //     icon: 'mdi-cog',
    //     title: 'Settings',
    //     desc: 'You can customize this template as you want'
    //   },
    //   {
    //     color: 'secondary',
    //     icon: 'mdi-account-multiple',
    //     title: 'Johny John',
    //     desc: 'Assign her new tasks'
    //   }
    // ];
    // const notifications = ref(notification);
    const priority = ref(customizer.setHorizontalLayout ? 0 : 0);

    watch(priority, (newPriority) => {
      // yes, console.log() is a side effect
      priority.value = newPriority;
    });

    return {
      customizer,
      // notifications,
      priority,
      setMiniSidbar,
      SetSidebarIDrawer,
      auth
    };
  }
});
</script>
