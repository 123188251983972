<template>
  <v-app
    v-if="mounted && master.loaded"
    theme="light"
  >
    <LayoutsHeader />
    <LayoutsSidebar />
    <v-main>
      <v-container
        fluid
        class="page-wrapper"
      >
        <slot />
      </v-container>
    </v-main>
    <div
      v-for="(messages, type) in baseStore.state.value.toastMessages"
      :key="type"
    >
      <div
        v-for="(show, message) in messages"
        :key="message"
      >
        <v-snackbar
          v-if="show"
          v-model="baseStore.state.value.toastMessages[type][message]"
          :color="type"
          timeout="5000"
          content-class="text-center"
          class="snackbar"
        >
          <b>{{ message }}</b>
        </v-snackbar>
      </div>
    </div>
    <v-overlay v-model="baseStore.state.value.overlay" />
    <div
      v-if="baseStore.state.value.overlay"
      class="loading"
    >
      <v-progress-circular
        :size="50"
        indeterminate
        color="primary"
      />
    </div>
    <div
      v-if="mounted"
      class="fab-container"
    >
      <v-btn
        v-show="buttonActive"
        icon="mdi-chevron-up"
        color="secondary"
        @click="goToTop"
      />
    </div>
  </v-app>
</template>

<script lang="ts">
import { useBaseStore } from '@/composables/base-store';
export default defineComponent({
  setup() {
    const state = reactive({
      mounted: false,
      buttonActive: false,
      scroll: 0
    });

    const baseStore = useBaseStore();
    const { auth } = useAuth();
    const { loadMaster } = useMasterStore();
    const { master } = useMasterStore();

    // マスタデータ読み込み
    const loadMasterData = async (): Promise<void> => {
      // ログインチェック
      if (auth.value && auth.value.logedIn) {
        await loadMaster();
      }
    };
    loadMasterData();

    onMounted(() => {
      baseStore.onResize();
      window.addEventListener('resize', baseStore.onResize, { passive: true });
      window.addEventListener('scroll', scrollWindow); // スクロールの位置を監視
      state.mounted = true;
    });

    // FAB
    const scrollWindow = (): void => {
      const top = 50;
      state.scroll = window.scrollY;
      if (top <= state.scroll) {
        // 50px以上スクロールした場合のみボタンを表示
        state.buttonActive = true;
      } else {
        state.buttonActive = false;
      }
    };

    // TOPへスクロール
    const goToTop = (): void => {
      window.scroll(0, 0);
    };

    return {
      master,
      ...toRefs(state),
      baseStore,
      goToTop
    };
  }
});
</script>

<style>
*,
html,
body {
  font-size: 14px;
}
.v-input--density-default {
  --v-input-control-height: 35px;
}
.v-selection-control--density-default {
  --v-selection-control-size: 30px;
}
</style>
