/**
 * グローバルメニューデータ
 *
 * @description サイドバーのメニュー表示で利用する
 */
export const SIDEBAR_ITEMS = [
  // {
  //   title: 'ダッシュボード',
  //   icon: 'mdi-bulletin-board',
  //   to: '/dashboard'
  // },
  { header: 'プロジェクト管理', group: '/project' },
  {
    group: '/project',
    icon: 'mdi-school',
    title: 'BigAdvance',
    children: [
      {
        title: '金融機関管理',
        icon: 'mdi-bank',
        to: '/project/ba/bank',
        name: 'project-ba-bank'
      },
      {
        title: 'ご利用明細',
        icon: 'mdi-view-list',
        to: '/project/ba/invoice',
        name: 'project-ba-invoice'
      },
      {
        title: 'アカウント管理',
        icon: 'mdi-account-key',
        to: '/project/ba/user',
        name: 'project-ba-user'
      },
      {
        title: 'リリースノート',
        icon: 'mdi-message-bulleted',
        to: '/project/ba/changelog',
        name: 'project-ba-changelog'
      }
      // {
      //   title: "コラム管理",
      //   icon: "mdi-bank",
      //   to: "/project/ba/column",
      // },
      // {
      //   title: "補助金・助成金管理",
      //   icon: "mdi-bank",
      //   to: "/project/ba/subsidy",
      // },
    ]
  },
  {
    group: '/project',
    icon: 'mdi-forum',
    title: '経営掲示板',
    children: [
      {
        title: '相談一覧',
        icon: 'mdi-view-list',
        to: '/project/forum/topic',
        name: 'project-forum-topic'
      },
      {
        title: 'メール送信履歴',
        icon: 'mdi-view-list',
        to: '/project/forum/mail',
        name: 'project-forum-mail'
      }
    ]
  },
  {
    icon: 'mdi-deskphone',
    title: '補助金申請支援',
    children: [
      {
        title: '申込・問い合わせ一覧',
        icon: 'mdi-view-list',
        to: '/project/subsidy-support/contact',
        name: 'project-subsidy-support-contact'
      }
    ]
  },
  // {
  //   group: "/fukuri",
  //   model: false,
  //   icon: "mdi-store",
  //   title: "FUKURI",
  //   children: [
  //     {
  //       title: "おすすめクーポン管理",
  //       icon: "mdi-bank",
  //       to: "/project/fukuri/recommend",
  //     },
  //   ],
  // },
  { header: '設定', group: '/setting' },
  {
    group: '/setting',
    icon: 'mdi-school',
    title: 'BigAdvance',
    children: [
      {
        title: '機能管理',
        icon: 'mdi-application',
        to: '/setting/ba/config',
        name: 'setting-ba-config'
      }
    ]
  },
  { header: 'ツール', group: '/tool' },
  {
    title: 'バナー管理',
    icon: 'mdi-image-multiple',
    to: '/tool/banner',
    name: 'tool-banner'
  },
  {
    title: 'コラム管理',
    icon: 'mdi-view-list',
    to: '/tool/column',
    name: 'tool-column'
  },
  {
    title: 'メディア管理',
    icon: 'mdi-image-multiple',
    to: '/tool/media',
    name: 'tool-media'
  },
  { header: '管理者ツール', group: '/admin' },
  {
    group: '/admin',
    icon: 'mdi-account-settings',
    title: 'アカウント管理',
    children: [
      {
        title: 'ロール管理',
        icon: 'mdi-account-key',
        to: '/admin/role',
        name: 'admin-role'
      },
      {
        title: 'ユーザー管理',
        icon: 'mdi-account-edit',
        to: '/admin/user',
        name: 'admin-user'
      }
    ]
  },
  { header: '開発者ツール', group: '/dev' },
  {
    group: '/dev',
    title: 'システム設定',
    icon: 'mdi-cog',
    to: '/dev/setting/app',
    name: 'dev-setting-app'
  },
  {
    group: '/dev',
    icon: 'mdi-table-large',
    title: 'テーブル管理',
    children: [
      {
        title: 'settings',
        icon: 'mdi-table',
        to: '/dev/table/setting',
        name: 'dev-table-setting'
      },
      {
        title: 'admin_files',
        icon: 'mdi-table',
        to: '/dev/table/admin-file',
        name: 'dev-table-admin-file'
      }
    ]
  },
  {
    group: '/dev',
    icon: 'mdi-developer-board',
    title: 'ユーティリティ',
    to: '/dev/utils',
    name: 'dev-utils'
  }
];
