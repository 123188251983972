interface State {
  Sidebar_drawer: any;
  Customizer_drawer: boolean;
  SidebarColor: string;
  miniSidebar: boolean;
  navbarColor: string;
  setHorizontalLayout: boolean;
  darktheme: boolean;
}

export const useCustomizerStore = (): any => {
  const customizer = useState<State>('customizer', () => ({
    Sidebar_drawer: null,
    Customizer_drawer: false,
    SidebarColor: 'white', //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
    miniSidebar: false,
    navbarColor: '#123C7E',
    setHorizontalLayout: false, // Horizontal layout
    darktheme: false
  }));

  const SetSidebarIDrawer = (): void => {
    customizer.value.Sidebar_drawer = true;
  };
  const setMiniSidbar = (payload: any): void => {
    customizer.value.miniSidebar = payload;
  };

  const seCustomizerDrawer = (payload: any): void => {
    customizer.value.Customizer_drawer = payload;
  };
  const setSidebarColor = (payload: any): void => {
    customizer.value.SidebarColor = payload;
  };
  const setNavbarColor = (payload: any): void => {
    customizer.value.navbarColor = payload;
  };
  const setLayout = (payload: any): void => {
    customizer.value.setHorizontalLayout = payload;
  };

  return {
    SetSidebarIDrawer,
    setMiniSidbar,
    seCustomizerDrawer,
    setSidebarColor,
    setNavbarColor,
    setLayout,
    customizer
  };
};
